<template>
  <div>
    <v-card>
      <v-row no-gutters class="px-3">
        <div class="my-auto font-weight-black">
          SAMBUTAN KEPALA INSPEKTORAT
        </div>
        <v-spacer></v-spacer>
        <div>
          <ModalEdit />
        </div>
      </v-row>
      <v-divider></v-divider>

      <div class="px-3">
        <v-alert v-model="alertBerhasil" type="success" dense dismissible class="mt-3">
          {{ alertMassage }}
        </v-alert>
        <v-alert v-model="alertGagal" type="error" dense dismissible class="mt-3">
          {{ alertMassage }}
        </v-alert>
      </div>

      <v-card-text>
        <v-row>
          <v-col cols="12" md="2" class="outlined my-auto text-center">
            <v-avatar size="150">
              <v-img :src="getIMG(item.profil_file)"></v-img>
            </v-avatar>
          </v-col>
          <v-col cols="12" md="10" v-html="item.profil_konten">
            {{ item.profil_konten }}
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ModalEdit from "@/components/Admin/profil/sambutan/modalEdit";

import refreshView from "@/store/profil/sambutan/viewSambutan";

export default {
  components: {
    ModalEdit,
  },

  computed: {
    refresh: {
      get() {
        return refreshView.state.Refresh;
      },
    },

    alertMassage: {
      get() {
        return refreshView.state.alertMassage;
      },
    },

    success: {
      get() {
        return refreshView.state.success;
      },
      set(value) {
        refreshView.commit("alert", value);
      },
    },

    alertBerhasil: {
      get() {
        return refreshView.state.alertBerhasil;
      },
      set(value) {
        refreshView.commit("berhasilAlert", value);
      },
    },

    alertGagal: {
      get() {
        return refreshView.state.alertGagal;
      },
      set(value) {
        refreshView.commit("gagalAlert", value);
      },
    },
  },

  watch: {
    refresh() {
      this.getData();
      setTimeout(() => {
        this.alertGagal = false;
        this.alertBerhasil = false;
      }, 5000);
    },
  },

  data: () => ({
    session: "",

    item: {},
  }),

  async mounted() {
    this.getData();
  },

  methods: {
    getIMG(value) {
      if (value) {
        return process.env.VUE_APP_API_BASE + "upload/profilGambar/" + value;
      } else {
        return process.env.VUE_APP_API_BASE + "images/avatar-default.png";
      }
    },

    getData() {
      this.http
        .get(process.env.VUE_APP_API_BASE + "profil/sambutan")
        .then((res) => {
          refreshView.commit("refreshData", false);
          this.item = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
