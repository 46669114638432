<template>
  <v-dialog v-model="ModalEdit" :max-width="dekstop">
    <template v-slot:activator="{ on: modal, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            small
            fab
            text
            color="primary"
            v-bind="attrs"
            @click="openModal()"
            v-on="{ ...tooltip, ...modal }"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span>Edit Data</span>
      </v-tooltip>
    </template>

    <v-card>
      <v-toolbar dark color="primary" dense flat>
        <v-toolbar-title >Edit Sambutan</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-form ref="form">
        <div class="ml-5 mr-5 mt-5">
          <v-row>
            <v-col cols="12" md="3">
              <v-card
                class="d-flex align-center justify-center"
                outlined
                height="250"
                width="250"
                style="overflow-y: scroll"
              >
                <div>
                  <v-img :src="urlImage" max-width="181"></v-img>
                </div>
              </v-card>

              <v-file-input
                dense
                flat
                outlined
                prepend-icon
                accept="image/png, image/jpeg, image/bmp"
                placeholder="Pilih Foto"
                append-icon="mdi-camera"
                ref="avatar"
                class="mt-2"
                @change="onFile"
              ></v-file-input>
            </v-col>

            <v-col cols="12" md="9" class="mb-3">
              <tiptap-vuetify
                v-model="item.profil_konten"
                :extensions="extensions"
                placeholder="Silahkan Diisi Dengan Kata Sambutan Kepla Badan Inspektorat ..."
                :card-props="{ outlined: true }"
              />
            </v-col>
          </v-row>

          <hr />
          <div class="text-right mt-5 pb-5">
            <v-btn v-if="btnLoading" color="primary" depressed @click="edit()"
              >SIMPAN</v-btn
            >
            <v-btn v-else color="primary" depressed loading>SIMPAN</v-btn>
          </div>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import refreshView from "@/store/profil/sambutan/viewSambutan";

import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from "tiptap-vuetify";

export default {
  components: { TiptapVuetify },

  created() {
    if (
      this.$vuetify.breakpoint.name === "xs" ||
      this.$vuetify.breakpoint.name === "sm"
    ) {
      this.dekstop = "100%";
    }
  },

  data: () => ({
    session: "",
    dekstop: "80%",
    ModalEdit: false,
    btnLoading: true,

    profil_file: "",
    urlImage: "",

    item: {},

    extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3],
          },
        },
      ],
      Bold,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak,
    ],

    // starting editor's content
    content: "",
  }),

  methods: {
    async openModal() {
      this.session = await JSON.parse(Cookie.dec(Cookie.get("myCookie")));
      this.getData();
      this.ModalEdit = true;
    },

    closeModal() {
      this.ModalEdit = false;
      this.content = "";
      this.urlImage = "";
    },

    getData() {
      this.http
        .get(process.env.VUE_APP_API_BASE + "profil/sambutan")
        .then((res) => {
          this.item = res.data.data;
          this.urlImage =
            res.data.data.profil_file === ""
              ? process.env.VUE_APP_API_BASE + "images/avatar-default.png"
              : process.env.VUE_APP_API_BASE +
                "upload/profilGambar/" +
                res.data.data.profil_file;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async edit() {
      this.btnLoading = false;

      const data = new FormData();
      data.append("profil_konten", this.item.profil_konten);
      data.append("profil_file", this.profil_file);

      const url = process.env.VUE_APP_API_BASE + "profil/sambutan";
      this.http
        .put(url, data, {
          headers: {
            Authorization: "Bearer " + this.session.token,
          },
        })
        .then((response) => {
          this.btnLoading = true;
          if (response.data.success) {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("berhasilAlert", true);
            refreshView.commit("gagalAlert", false);
            refreshView.commit("success", response.data.success);
          } else {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", response.data.success);
          }
          this.closeModal();
        })
        .catch((error) => {
          refreshView.commit("refreshData", true);
          refreshView.commit("alert", error.response.data.message);
          refreshView.commit("gagalAlert", true);
          refreshView.commit("berhasilAlert", false);
          refreshView.commit("success", error.response.data.success);
          console.log(error.response.status);
          this.btnLoading = true;
          this.closeModal();
        });
    },

    onFile(value) {
      this.profil_file = value;
      this.urlImage = URL.createObjectURL(this.profil_file);
    },
  },
};
</script>
