<template>
  <div>
    <v-alert>
      <h3>Tentang Inspektorat</h3>
    </v-alert>

    <div class="mb-5">
      <CSambutan />
    </div>

    <div>
      <CTentang />
    </div>
  </div>
</template>

<script>
import CSambutan from "@/components/Admin/profil/sambutan/sambutan";
import CTentang from "@/components/Admin/profil/tentang/tentang";

export default {
  components: {
    CSambutan,
    CTentang
  },

  data: () => ({
    session: "",
  }),
};
</script>
